<template>
  <section id="culture" class="left">
    <p class="title">企业文化</p>
    <div class="desc">云隆精神是云隆企业文化的重要组成部分，是我们共同认可的行为准则。</div>
    <div class="slick-slider carousel slick-initialized" dir="ltr">
      <div class="slick-list">
        <el-carousel :interval="5000" arrow="always" class="slick-slide slick-cloned" height="7rem" :indicator-position="none">
          <el-carousel-item v-for="item in list" :key="item" class="m-banner-ul clearfix">
            <div>
              <div class="culture-wrapper" tabindex="-1" style="width:100%;display:inline-block">
                <img :src="item.imgUrl" alt="多元兼容" style="width: 100%">
                <div class="bottom">
                  <p class="title">{{item.title}}</p>
                  <ul class="desc-container">
                    <li class="desc zh-desc">{{item.cont}}</li>
                  </ul>
                  <br>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </section>
</template>

<script>
import { readonly } from 'vue'
export default {
  setup () {
    const list = readonly([
      {
        imgUrl: require('../../../assets/img/home.jpeg'),
        title: '管理理念',
        cont: '严格 严谨 严密  宽松 宽厚 宽容'
      },
      {
        imgUrl: require('../../../assets/img/home1.jpeg'),
        title: '企业宗旨',
        cont: '服务客户 发展企业 造福员工 回报社会'
      },
      {
        imgUrl: require('../../../assets/img/home2.jpeg'),
        title: '核心价值观',
        cont: '敬业 创新 诚信 共赢'
      }
    ])

    return {
      list
    }
  }
}
</script>

<style>
#culture .slick-list >>> .el-carousel__arrow {
  top: 85%;
  transform: none;
}
</style>

<style lang="scss" scoped>
section {
  position: relative;
  width: 100%;
  padding: 0 9vw;
  margin: 11vw auto 0;
  box-sizing: border-box;
}
section.left {
  flex-flow: row wrap;
}
section.left, section.right {
  display: flex;
  justify-content: space-between;
}

section.left>.title, section.right>.title {
  position: absolute;
  top: .2rem;
}

section>.title {
  font-size: .48rem;
  line-height: .56rem;
}
.title {
  font-family: "Gilroy-Bold",-apple-system,"Sofia Pro","Microsoft YaHei","Helvetica","Arial",sans-serif;
  font-weight: 700;
}

section.left>.desc, section.right>.desc {
  margin-top: 9vw;
  flex: .9;
}

section>.desc {
  font-size: .22rem;
}

.slick-slider {
  position: relative;
  overflow-x: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#culture .carousel {
  width: 47.5vw;
  max-width: 9.12rem;
  margin-right: 0;
  background-color: #fff;
  text-align: center;
  overflow: hidden;
  border-radius: 18px;
}

#culture .carousel .slick-arrow.slick-prev {
  border: 0.1rem solid #fff;
  border-left: 0.63rem solid #fff;
}

#culture .carousel .slick-arrow {
  background-color: #fff;
  box-sizing: content-box;
  top: calc(27vw + .12rem);
}

.slick-arrow.slick-prev {
  left: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABqCAMAAAA7gKe8AAAANlBMVEUAAACrzf+ny/+rzf+rzf+iz/+qyv+rzv+rzf+rzv+rzf+szv+qzP+rzv+rzf+ry/+ny/+rzf8aBjGUAAAAEXRSTlMA3yCA7xAwz6CQcF9Qv69AQAXdXlMAAAEwSURBVFjD7ZlRDsIgEESBAkVqW7n/ZTUxpln/dh/RRpn/2aQzfUto3feUaikL8N9ie8gHq7+2p7zRP7WXbP7S0IA0NzQg+IYG5MNvCnGJwh8s8R+a1f6L8JekjX8T/kkd/yz8ldUXrxZ6Dvmsp6dnfas6/iL8Fz09sD4v/AujJ+Z/o2el8TN68qCH0BO60hP19ERITxv09KNn1/qvcHlVGf+gh9DjP05PkPUlp5UX9BhuDu/xE36rM6gAesCAno+w0xDdKinSK4M9QF5luQsBTAxnvlDkSpMT2FLdEphwlMEPFn6t4ocrPN5NdFO2JsxWPR1brX6DLVdkGYMtwBZedFtntoqljB88t2xsnW7RrYY662CrF1vTGdiKndnq8HEesuXoFQH/IuE/aaza/bPGO08iWl0SVOokAAAAAElFTkSuQmCC);
}

.slick-arrow {
  position: absolute;
  width: .18rem;
  height: .32rem;
  font-size: 0;
  color: transparent;
  background-size: 100% 100%;
  z-index: 5;
  cursor: pointer;
}

.slick-list .slick-track {
  height: 80%;
  box-sizing: border-box;
  transition: all .5s ease-out;
  width: 1000%;
}

.slick-list .slick-track .slick-slide {
  float: left;
  height: 100%;
}

.slick-list {
  position: relative;
  width: 100%;
  height: 100%;
}

.slick-list .slick-track .slick-slide>div {
  width: unset !important;
  height: 100%;
}

#culture .carousel .culture-wrapper {
  overflow: hidden;
}

#culture .carousel .culture-wrapper img {
  display: block;
  margin: auto;
}

#culture .carousel img {
  width: 100%;
  height: 27vw;
}

#culture .carousel .culture-wrapper .bottom {
  background: #fff;
  padding: 0 8vw;
  box-sizing: border-box;
}

#culture .carousel .title {
  margin-top: .1rem;
  margin-bottom: .18rem;
  font-size: .30rem;
  line-height: .54rem;
  text-align: left;
  transform: scale(1) !important;
}

#culture .carousel .desc-container .desc {
  font-size: .2rem;
  transform-origin: center;
  text-align: left;
  position: relative;
  margin-bottom: 0.05rem;
}

#culture .carousel .desc-container .desc::after {
  content: '';
  position: absolute;
  left: -0.15rem;
  top: .1rem;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #000;
}

#culture.zh .carousel .desc-container .desc::after, #culture.ja .carousel .desc-container .desc::after {
  top: .15rem;
}

.slick-arrow.slick-next {
  right: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABqCAMAAAA7gKe8AAAANlBMVEUAAACnzP+rzf+rzf+jz/+qyv+rz/+rzv+ry/+rzf+rzP+rzv+szv+rzv+rzf+qzP+qy/+rzf9+oiD6AAAAEXRSTlMAIN/vEDB/z4CgcJBfv69QQIHJ95kAAAEhSURBVFjD7ZnLkoUgDAWJARXFR/7/Z2c2UzVMzSrNlfswe7tKjh2ChBYVl5yLBHfJYN81JPa8mR5OQLGfGn2AbBUBAWyPDsBiv2oQR4gDJUhF0JMSbHEQJjNjYawVYXUQ5oowiSdNGkaq40w4ztIjjLgDQhUGcGvGYRz6Dm7F7XbrIW5ZH7fy7dZ/bh04zvEJ3Mq3Wz2HiNy40W3REcbzuZUwQegQsQUaRqAEcEhBAGWAsXoF2KBoi9yh1SpXf8qnks7GdR5ZQ4l/WhpsqvPVI1NSFl/Rlss/CdzeIxsw5qt31QQPMEdTe6bPtkcd9rDlL9bUnnjbgwa5vvbsn2bP+V72hBezR6A9Qn+NF3quznXzQoA1eGqp4/PfL2jpc030BUBPWtoMJ3YEAAAAAElFTkSuQmCC);
}

#culture .carousel .slick-arrow.slick-next {
  border: 0.1rem solid #fff;
  border-right: 0.63rem solid #fff;
}
</style>
