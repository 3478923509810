<template>
  <!-- <div class="m-banner m-banner-home" ref="divRef">
    <ul class="m-banner-ul clearfix" ref="ulRef">
      <li v-for="item in banners" :key="item.file_url">
        <img :src="item.imgUrl" lat="" class="m-banner-img" />
      </li>
    </ul> -->
    <!-- <div class="m-banner-buttons" ref="currentRefs">
      <span :index="index" v-for="(item, index) in banners" :class="[currentIndex === index ? 'on' : '']" :key="index" @click="handleClick(index)"></span>
    </div> -->
  <!-- </div> -->
   <el-carousel :interval="5000" arrow="never" class="m-banner m-banner-home" height="100vh" direction="vertical" :pause-on-hove="false">
    <el-carousel-item v-for="item in banners" :key="item" class="m-banner-ul clearfix">
      <img :src="item.imgUrl" lat="" class="m-banner-img" />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  props: ['banners']
  // setup (props) {
  //   const divRef = ref(null)
  //   const ulRef = ref(null)
  //   let timer = null
  //   const currentIndex = ref(0)
  //   // 获取到当前
  //   watch(() => props.banners, (val) => {
  //     if (val.length > 0) {
  //       nextTick(() => {
  //         ulRef.value.appendChild(ulRef.value.children[0].cloneNode(true))
  //         clearInterval(timer)
  //         timer = setInterval(autoplay, 2000)
  //       })
  //     }
  //   })

  //   function autoplay () {
  //     // 播放张数
  //     currentIndex.value++

  //     // 判断当前是否是播放完毕
  //     // console.log('%c 🍾 ulRef.value.children.length: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', ulRef.value.children.length)
  //     if (currentIndex.value === ulRef.value.children.length - 1) {
  //       currentIndex.value = 0
  //       ulRef.value.style.transform = `translate3d(-${0 * ulRef.value.children[0].offsetWidth}px, 0, 0)`
  //     } else {
  //       scroll()
  //     }
  //   }

  //   function scroll () {
  //     ulRef.value.style.transform = `translate3d(-${currentIndex.value * ulRef.value.children[0].offsetWidth}px, 0, 0)`
  //     // console.log('%c 🍨 currentIndex.value: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;', currentIndex.value)
  //   }

  //   onUnmounted(() => {
  //     clearInterval(timer)
  //   })
  //   return { divRef, ulRef }
  // }
}
</script>

<style lang="scss" scoped>
// .m-banner {
//   position: relative;
//   width: 100%;
//   overflow: hidden;
//   top: -1rem;
//   &-img {
//     object-position: left;
//     width: 100vw;
//     height: 100vh;
//   }
//   &-ul {
//     width: 1000%;
//      transition: transform .6s ease-out;
//     > li {
//       float: left;
//     }
//   }
//   &-buttons {
//     position: absolute;
//     transform: translateX(-50%);
//     bottom: .26rem;  /*距离底部20px*/
//     left: 50%;  /*左右居中显示*/
//     z-index: 100;
//     > span {
//       cursor: pointer;
//       float: left;
//       width: 1.6vw;
//       height: 1.6vw;
//       max-width: .24rem;
//       max-height: .24rem;
//       border-radius: 50%;
//       border: 0.02rem solid rgba(255,255,255,0.5);
//       margin-right: .066rem;
//     }
//   }
// }
// .on{
//   background: rgba(255,255,255,0.5);
// }
</style>
