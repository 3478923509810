<template>
  <section id="About" class="m-about">
    <p class="m-about-title">加入我们</p>
    <div class="m-about-wrapper">
      <div class="m-about-wrapper-item" v-for="item in list" :key="item.title">
        <p>{{item.title}}</p>
        <a href="#">{{item.cont}}</a>
      </div>
    </div>
  </section>
</template>

<script>
import { readonly } from 'vue'
export default {
  setup () {
    const list = readonly([
      {
        title: '邮箱',
        cont: 'yunlongsuport@yunlongtech.com'
      },
      {
        title: '电话',
        cont: '028 8500 9419'
      },
      {
        title: '地址',
        cont: '成都市武侯区武侯新城管委会武科西二路189号中铁隆大厦1栋3层1-303号'
      },
      {
        title: '传真',
        cont: '028 85009203'
      }
    ])
    return { list }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/variable.scss';
.m-about {
  @include transform-center(leftCenter);
  width: 84%;
  padding: 30px 20px 40px 20px;
  // margin: 11vw auto 0;
  bottom: -13%;
  z-index: 99;
  background: #FFFFFF;
  box-shadow: 0px 6px 21px 0px rgba(186, 186, 186, 0.4);
  border-radius: 10px;
  box-sizing: border-box;
  &-title {
    font-family: "Gilroy-Bold",-apple-system,"Sofia Pro","Microsoft YaHei","Helvetica","Arial",sans-serif;
    font-weight: 700;
    font-size: .48rem;
    line-height: .56rem;
  }
  &-wrapper {
    margin-top: 0.57rem;
    @include flex-center(flex-start, center, wrap);
    &-item {
      flex: 0 0 50%;
      margin-bottom: .4rem;
      > p {
        font-size: .29rem;
        font-weight: bolder;
        line-height: 1.8;
      }
      > a {
        font-size: .22rem;
        color: #3c8cff;
        line-height: 1.8;
      }
    }
  }
}

</style>
